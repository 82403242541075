import { Link } from "react-scroll"
import logo from '../../assets/evoguard_icon.svg'
import titleWeb from '../../assets/footer_title.svg'
import titleMobil from '../../assets/mobil_footer_title.svg'
import upLogo from '../../assets/upLogo.svg'
import './Footer.scss'
import FooterLinks from "./FooterLinks"
import FooterSocialIcons from './FooterSocialIcons'

interface IFooterProps {
    isMobileView: boolean
}

function Footer({ isMobileView }: IFooterProps) {
    const getFooterTitle = () => {
        return isMobileView ? titleMobil : titleWeb
    }
    return (
        <div className="footer_wrapper" data-testid="footer">
            <div className="footer_title_wrapper">
                <div className="footer_title_sub_wrapper">
                    <img className="footer_title" src={getFooterTitle()} />
                    <FooterSocialIcons />
                </div>
            </div>
            <div className="footer_logo_part">
                <img className="footer_logo" src={logo} />
                <div className="icon_up_wrapper">
                    <Link className="icon_up_link" spy to="header_wrapper">
                        <img className='icon_up_image' src={upLogo} />
                    </Link>
                </div>
            </div>
            <FooterLinks />
        </div>
    )
}

export default Footer
