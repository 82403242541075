import AccordionComponent from '../AccordionComponent/AccordionComponent'
import { TitelTexts } from '../../language/Texts'
import { IAttribute } from '../../models/IOutputData'
import './ProductAttributes.scss'

interface ProductAttributesProps {
    productAttributesList: IAttribute[]
}

function ProductAttributes({ productAttributesList }: ProductAttributesProps) {

    const componentBody =
        <div className="products_attributes_wrapper" data-testid="product_attributes" >
            {productAttributesList.map((item, index) => {
                return (
                    <div key={index} className="single_attribute_wrapper">
                        <p className="attribute_title">{item.title}</p>
                        <p className="attribute_value">{item.value}</p>
                    </div>
                )
            })}
        </div>

    const layOut = () => {
        return (
            <AccordionComponent
                title={TitelTexts.productAttributes}
                body={componentBody}
                eventKey="1"
            />
        )
    }

    return (
        productAttributesList.length > 0 ? layOut() : null
    )
}

export default ProductAttributes