import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { HiOutlineChevronDown } from "react-icons/hi";
import { Languages } from '../../language/Texts';
import { BootstrapInputStyle2 } from '../../utils/SelectCompStyle';


interface DocLanguageOptProps {
    setLanguageOpt: (vlaue: string) => void,
    languageOpt: string

}

export default function DocLanguageOpt({ setLanguageOpt, languageOpt }: DocLanguageOptProps) {
    const handleChange = (event: { target: { value: string } }) => {
        setLanguageOpt(event.target.value);
    };
    return (
        <FormControl variant="standard" className='doc_lang_form'>
            <InputLabel className='doc_lang_label'>{Languages.language}</InputLabel>
            <Select
                className='doc_lang_select'
                IconComponent={HiOutlineChevronDown}
                value={languageOpt}
                onChange={handleChange}
                input={<BootstrapInputStyle2 />}
            >
                <MenuItem className='doc_select_menu' key={'1'} value={"single"}>{Languages.myLanguage} &emsp; </MenuItem>
                <MenuItem  className='doc_select_menu' key={'2'} value={"all"}>{Languages.allLanguages} &emsp; </MenuItem>
            </Select>
        </FormControl>
    );
}

