import { FooterTexts } from "../../language/Texts"
import { IOutputData } from "../../models/IOutputData"
import Sales from "./Sales"
import Share from "./Share"
import Support from "./Support"
import './Contact.scss'

interface ContactProps {
    component: IOutputData
}

function Contact({ component }: ContactProps) {

    return (
        <div className='contact_wrapper'>
            <div className="contact_title"> {FooterTexts.contactTitle}</div>
            <p className="contact_text_1">{FooterTexts.contactText_1}</p>
            <div className="footer_last_part">
                <p className="contact_text_2">{FooterTexts.contactText_2}</p>
                <div className="contact_sections">
                    <Support component={component} />
                    <Sales component={component} />
                </div>
                <Share component={component} />
            </div>

        </div>
    )
}

export default Contact
