import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaXing, FaYoutube } from "react-icons/fa"
import { Links } from "../../language/Texts"

function FooterSocialIcons() {
    return (
        <div className="footer_icon_section">
            <a href={Links.facebook} target="_blank" rel="noopener noreferrer"><FaFacebookF className="social_icon" /></a>
            <a href={Links.twitter} target="_blank" rel="noopener noreferrer"><FaTwitter className="social_icon" /></a>
            <a href={Links.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram className="social_icon" /></a>
            <a href={Links.xing} target="_blank" rel="noopener noreferrer"><FaXing className="social_icon" /></a>
            <a href={Links.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedinIn className="social_icon" /></a>
            <a href={Links.youtube} target="_blank" rel="noopener noreferrer"><FaYoutube className="social_icon" /></a>
        </div>
    )
}

export default FooterSocialIcons
