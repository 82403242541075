import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en.json'
import translationDE from './locales/de.json'
import translationES from './locales/es.json'
import translationFR from './locales/fr.json'
import translationPT from './locales/pt.json'
import { Utils } from "../utils/Utils";

const lang = Utils.getBrowserLanguage()
i18n.use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        lng: lang,
        resources: {
            en: { translation: translationEN },
            de: { translation: translationDE },
            fr: { translation: translationFR },
            pt: { translation: translationPT },
            es: { translation: translationES }
        }
    })

export default i18n