import { useEffect, useState } from 'react'
import { CgPushDown } from "react-icons/cg"
import { HiOutlineChevronRight } from "react-icons/hi"
import { SiAdobeacrobatreader } from "react-icons/si"
import { GeneralTexts, TitelTexts } from "../../language/Texts"
import i18n from "../../language/i18n"
import { IDocument } from "../../models/IDocument"
import { Utils } from "../../utils/Utils"
import AccordionComponent from "../AccordionComponent/AccordionComponent"
import DocLanguageOpt from "./DocLanguageOpt"
import './Documents.scss'

interface DocumentProps {
    documentList: IDocument[]
    languageOpt: string
    setLanguageOpt: (langOpt: string) => void
}
function Documents({ documentList, languageOpt, setLanguageOpt }: DocumentProps) {
    const [clickedItemIndex, setClickedItemIndex] = useState<number[]>([])
    const [filteredDocumentList, setFilteredDocumentList] = useState<IDocument[]>()
    const userLang = i18n.language ?? Utils.getBrowserLanguage()

    useEffect(() => {
        filterDocumentList()
    }, [languageOpt])

    const filterDocumentList = () => {
        if (languageOpt === "single") {
            const output: IDocument[] = []
            documentList.forEach(item => {
                if (item?.languages?.length === 0) {
                    output.push(item)
                }
                else {
                    item?.languages?.forEach(e => {
                        if (e.substring(0, 2) === userLang) {
                            item.languages?.sort()
                            output.push(item)
                        }
                    })
                }
            })
            if (output.length === 0) {
                setLanguageOpt("all")
                setFilteredDocumentList(documentList)
                return
            }
            setFilteredDocumentList(output)
        }
        else {
            setFilteredDocumentList(documentList)
        }
    }

    const handleClick = (index: number) => {
        setClickedItemIndex((prev => [...prev, index]))
    }

    const displayDownloadIcon = (index: number) => {
        if (clickedItemIndex.includes(index)) {
            return (
                <div className="download_icon_wrapper">
                    <CgPushDown className="download_icon" />
                </div>)
        } else {
            return null
        }
    }

    const getClickedClassName = (index: number) => {
        return clickedItemIndex.includes(index) && 'clicked_doc_general_part'
    }

    const componentBody =
        <div className="documents_wrapper">
            <div className="language_select">
                <DocLanguageOpt
                    setLanguageOpt={setLanguageOpt}
                    languageOpt={languageOpt}
                />
            </div>
            <div className="document_list">
                {filteredDocumentList?.map((document, index) => {
                    return (
                        <div key={index} className="document_item_wrapper">
                            <div className={`doc_general_part ${getClickedClassName(index)}`}>
                                <p className="doc_general_text">{GeneralTexts.documentation}</p>
                                <SiAdobeacrobatreader className="acrobat_icon" />
                            </div>
                            <div className="doc_link_part">
                                {displayDownloadIcon(index)}
                                <div>
                                    <a
                                        className="doc_link"
                                        href={document.link}
                                        onClick={() => handleClick(index)}
                                        rel="noreferrer"
                                        type={document.mimeType}
                                        target="_blank"
                                        data-testid="document_link"
                                    >
                                        {document.title}
                                        <span className="doc_link_icon_wrapper">
                                            <HiOutlineChevronRight className="doc_link_icon" />
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </div>

    const layOut = () => {
        return (
            <AccordionComponent
                title={TitelTexts.actualProductDocuments}
                body={componentBody}
                eventKey="-1"
            />
        )
    }

    return (
        documentList?.length > 0 ? layOut() : null
    )
}

export default Documents