import AccordionComponent from '../AccordionComponent/AccordionComponent'
import ISparePart from '../../models/ISparePart'
import { Utils } from '../../utils/Utils'
import { GeneralTexts, TitelTexts } from '../../language/Texts'
import './SparePart.scss'

interface SparePartProp {
    spareParts: ISparePart[],
    isMobileView: boolean
}

function SparePart({ spareParts, isMobileView }: SparePartProp) {

    const displayPositionLabel = (index?: number) => {
        if (index === 0 || isMobileView) {
            return <p className="attribute_position_label">{GeneralTexts.positionNumber}</p>
        } else {
            return null
        }
    }

    const getQuantityClassName = (index: number) => {
        return index === 0 ? "attribute_quantity first_quantity_item" : "attribute_quantity"
    }

    const getValueClassName = (index: number) => {
        return index === 0 ? "spare_part_value first_value_item" : "spare_part_value"
    }

    const componentBody =
        <div className='spare_part_wrapper' data-testid="spare_part">
            {
                Utils.sortSpareParts(spareParts)?.map((attribute: ISparePart, index) => {
                    return (
                        <div key={index} className="spare_item">
                            <p className={getQuantityClassName(index)}>
                                {attribute.quantity} x {attribute.name}
                            </p>
                            <div className={getValueClassName(index)}>
                                <p className="attribute_type" >{attribute.componentType}</p>
                                <div className="attribute_position_wrapper">
                                    {displayPositionLabel(index)}
                                    <p className="attribute_position">
                                        {attribute.position !== undefined && attribute.position}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    const layOut = () => {
        return (
            <AccordionComponent
                title={TitelTexts.spareParts}
                body={componentBody}
                eventKey="1"
            />
        )
    }

    return (
        spareParts.length > 0 ? layOut() : null
    )
}

export default SparePart
