import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Link } from 'react-scroll';
import { GeneralTexts, TitelTexts } from '../../language/Texts';
import { BootstrapInputStyle1 } from '../../utils/SelectCompStyle';
import './SelectBar.scss';

interface SelectBarProps {
    isMobileView: boolean
    displayParts: {
        isDocumentDisplay: boolean,
        isSparePartDisplay: boolean,
        isProductAttrDisplay: boolean
    }
}

function SelectBar({ isMobileView, displayParts }: SelectBarProps) {
    const { isDocumentDisplay, isSparePartDisplay, isProductAttrDisplay } = displayParts
    const [choose, setChoose] = useState('general')
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const options = [
        { value: 'general', label: TitelTexts.general },
        { value: 'documents', label: TitelTexts.actualProductDocuments },
        { value: 'sparePart', label: TitelTexts.spareParts },
        { value: 'productAttributes', label: TitelTexts.productAttributes },
        { value: 'contact', label: GeneralTexts.contact },
    ];

    const displayOptions = options.filter(item => {
        if (item.value === 'documents' && isDocumentDisplay) {
            return item
        } else if (item.value === 'sparePart' && isSparePartDisplay) {
            return item
        } else if (item.value === 'productAttributes' && isProductAttrDisplay) {
            return item
        } else if (item.value === 'general' || item.value === 'contact') {
            return item
        }
    })

    // On mobile view auto select feature
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const autoSelection = (item: { value: string; label: string; }) => {
        const section = document.getElementById(item.value)!;
        const selected =
            scrollPosition >= section.offsetTop - 20 &&
            scrollPosition < section.offsetTop + section.offsetHeight - 20;
        if (selected && choose !== item.value) {
            setChoose(item.value);
        }
        return selected
    }

    const handleChange = (event: { target: { value: string } }) => {
        document.getElementById(event.target.value + '_link')!.click()
        setChoose(event.target.value);
    };

    const layOut = () => {
        return (
            <div className='selectbar_wrapper' data-testid="selectbar" >
                <FormControl className='selectbar_form' variant="standard" >
                    <Select
                        className='selectbar_select'
                        IconComponent={HiOutlineChevronDown}
                        value={choose}
                        onChange={handleChange}
                        input={<BootstrapInputStyle1 />}
                    >
                        {displayOptions?.map((item, index) => {
                            // Auto selection with useEffect
                            const selected = autoSelection(item)
                            return (
                                <MenuItem
                                    className='selectbar_menu'
                                    key={index}
                                    selected={selected}
                                    value={item.value}
                                >
                                    {item.label}
                                    <Link spy to={item.value} id={item.value + '_link'} />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>
        )
    }
    return isMobileView ? layOut() : null
}

export default SelectBar