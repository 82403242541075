import i18n from './i18n';

export class GeneralTexts {
    private constructor() { }
    static get componentType() { return i18n.t('componentType') }
    static get serialNumber() { return i18n.t('serialNumber') }
    static get productionDate() { return i18n.t('productionDate') }
    static get productInfo() { return i18n.t('productInfo') }
    static get positionNumber() { return i18n.t('positionNumber') }
    static get documentation() { return i18n.t('documentation') }
    static get contact() { return i18n.t('contact') }
}

export class TitelTexts {
    private constructor() { }
    static get general() { return i18n.t('general') }
    static get productAttributes() { return i18n.t('productAttributes') }
    static get actualProductDocuments() { return i18n.t('actualProductDocuments') }
    static get spareParts() { return i18n.t('spareParts') }
    static get contact() { return i18n.t('contact') }
}

export class Languages {
    private constructor() { }
    static get language() { return i18n.t('language') }
    static get myLanguage() { return i18n.t('myLanguage') }
    static get allLanguages() { return i18n.t('allLanguages') }
    static get german() { return i18n.t('german') }
    static get english() { return i18n.t('english') }
    static get france() { return i18n.t('france') }
    static get spanish() { return i18n.t('spanish') }
    static get portuguese() { return i18n.t('portuguese') }
}

export class FooterTexts {
    private constructor() { }
    static get contactTitle() { return i18n.t('contactTitle') }
    static get contactText_1() { return i18n.t('contactText_1') }
    static get contactText_2() { return i18n.t('contactText_2') }
    static get shareProduct() { return i18n.t('shareProduct') }
    static get contactSupport() { return i18n.t('contactSupport') }
    static get contactSales() { return i18n.t('contactSales') }
    static get imprint() { return i18n.t('imprint') }
    static get termOfUse() { return i18n.t('termOfUse') }
    static get dataProtection() { return i18n.t('dataProtection') }
    static get integritySystem() { return i18n.t('integritySystem') }
    static get conditions() { return i18n.t('conditions') }
}

export class Links {
    private constructor() { }
    static get facebook() { return i18n.t('facebook') }
    static get instagram() { return i18n.t('instagram') }
    static get twitter() { return i18n.t('twitter') }
    static get xing() { return i18n.t('xing') }
    static get linkedin() { return i18n.t('linkedin') }
    static get youtube() { return i18n.t('youtube') }
    static get impirintLink() { return i18n.t('impirintLink') }
    static get termOfUseLink() { return i18n.t('termOfUseLink') }
    static get dataLProtectionLink() { return i18n.t('dataLProtectionLink') }
    static get cookiesLink() { return i18n.t('cookiesLink') }
    static get integritySystemLink() { return i18n.t('integritySystemLink') }
    static get conditionsLink() { return i18n.t('conditionsLink') }
    static get homePage() { return i18n.t('homePage') }
}

export class MailTexts {
    private constructor() { }
    static get sentTo() { return "customer.support@evoguard.com" }
    static get salesMail() { return "sales@evoguard.com" }
    static get greeting() { return i18n.t('evoguardGreeting') }
    static get infoText() { return i18n.t('evoguardInfoText') }
    static get regard() { return i18n.t('evoguardRegard') }
    static get signature() { return i18n.t('evoguardSignature') }
    static get sentBy() { return i18n.t('evoguardSentBy') }
    static get howCanWeHelp() { return i18n.t('evoguardHowCanWeHelp') }
    static get insertYourData() { return i18n.t('evoguardInsertYourData') }
    static get firstnameLastname() { return i18n.t('evoguardFirstnameLastname') }
    static get company() { return i18n.t('evoguardCompany') }
    static get phoneAndMail() { return i18n.t('evoguardPhoneAndMail') }
    static get allowedToInsertSignature() { return i18n.t('evoguardAllowedToInsertSignature') }
    static get urgentCase() { return i18n.t('evoguardUrgentCase') }
}
