import { useState } from 'react'
import { IOutputData } from '../../models/IOutputData'
import Contact from '../Contact/Contact'
import Documents from '../Documents/Documents'
import General from '../General/General'
import ProductAttributes from '../ProductAttributes/ProductAttributes'
import SparePart from '../SparePart/SparePart'

interface NavbarBodyProps {
    component: IOutputData
    isMobileView: boolean
}

function NavbarBody({ component, isMobileView }: NavbarBodyProps) {
    //In order to test languageOpt on Document, we give it as props
    const [languageOpt, setLanguageOpt] = useState("single")
    return (
        <div data-testid="navbar_body">
            <section id="general">
                <General
                    articleDescriptionS={component?.articleDescriptionS}
                    generalAttributeList={component?.generalAttributesList}
                />
            </section>
            <section id="documents">
                <Documents
                    documentList={component?.productDocuments}
                    languageOpt={languageOpt}
                    setLanguageOpt={setLanguageOpt}
                />
            </section>
            <section id="sparePart">
                <SparePart
                    spareParts={component?.spareParts}
                    isMobileView={isMobileView}
                />
            </section>
            <section id="productAttributes">
                <ProductAttributes
                    productAttributesList={component?.productAttributesList}
                />
            </section>
            <section id="contact">
                <Contact
                    component={component} />
            </section>
        </div>
    )
}

export default NavbarBody
