import NavbarBody from './NavbarBody'
import NavbarHeader from './NavbarHeader'
import { IOutputData } from '../../models/IOutputData'
import './Navbar.scss'

interface NavbarProps {
    component: IOutputData
    isMobileView: boolean
    displayParts: {
        isDocumentDisplay: boolean,
        isSparePartDisplay: boolean,
        isProductAttrDisplay: boolean
    }
}

function Navbar({ component, isMobileView , displayParts}: NavbarProps) {

    return (
        <div className='navbar_wrapper'  data-testid="navbar">
            {!isMobileView &&
                <NavbarHeader displayParts={displayParts} />
            }
            <NavbarBody
                isMobileView={isMobileView}
                component={component} />
        </div>
    )
}

export default Navbar
