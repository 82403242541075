import { AiOutlineCopyrightCircle } from "react-icons/ai"
import { FooterTexts, Links } from "../../language/Texts"

function FooterLinks() {
    return (
        <div className="footer_links">
            <span className="copy_right"><AiOutlineCopyrightCircle /> Evoguard GmbH 2023</span>
            <a href={Links.impirintLink} target="_blank" rel="noopener noreferrer" className="footer_link_item">
                {FooterTexts.imprint}
            </a>
            <a href={Links.termOfUseLink} target="_blank" rel="noopener noreferrer" className="footer_link_item">
                {FooterTexts.termOfUse}
            </a>
            <a href={Links.dataLProtectionLink} target="_blank" rel="noopener noreferrer" className="footer_link_item">
                {FooterTexts.dataProtection}
            </a>
            <a href={Links.integritySystemLink} target="_blank" rel="noopener noreferrer" className="footer_link_item">
                {FooterTexts.integritySystem}
            </a>
            <a href={Links.conditionsLink} target="_blank" rel="noopener noreferrer" className="footer_link_item">
                {FooterTexts.conditions}
            </a>
        </div>
    )
}

export default FooterLinks
