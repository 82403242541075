import AccordionComponent from "../AccordionComponent/AccordionComponent";
import { GeneralTexts, TitelTexts } from "../../language/Texts";
import { IGeneralAttributes } from "../../models/IGeneralAttributes";
import { Utils } from "../../utils/Utils";
import './General.scss';

export interface GeneralProps {
    generalAttributeList: IGeneralAttributes
    articleDescriptionS: string
}

function General({ generalAttributeList, articleDescriptionS }: GeneralProps) {

    const componentBody =
        <div className="general_wrapper">
            <p className="article_description">{articleDescriptionS}</p>
            {generalAttributeList.componentType &&
                <div className="item_wrapper">
                    <p className="general_item_key" >{GeneralTexts.componentType}</p>
                    <p className="general_item_value">{generalAttributeList.componentType}</p>
                </div>
            }
            {generalAttributeList.serialNumber &&
                <div className="item_wrapper">
                    <p className="general_item_key" >{GeneralTexts.serialNumber}</p>
                    <p className="general_item_value">{generalAttributeList.serialNumber}</p>
                </div>
            }
            {generalAttributeList.productionDate &&
                <div className="item_wrapper">
                    <p className="general_item_key" >{GeneralTexts.productionDate}</p>
                    <p className="general_item_value">{Utils.tryParseDate(generalAttributeList.productionDate)}</p>
                </div>
            }
            {generalAttributeList.productInfo &&
                <div className="item_wrapper">
                    <p className="general_item_key" >{GeneralTexts.productInfo}</p>
                    <p className="general_item_value">{generalAttributeList.productInfo}</p>
                </div>
            }
        </div>

    return (
        <AccordionComponent
            title={TitelTexts.general}
            body={componentBody}
            eventKey="-1"
        />
    )
}

export default General
