import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18n from '../../language/i18n';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Languages } from '../../language/Texts';
import { BootstrapInputStyle1 } from '../../utils/SelectCompStyle';
import './LanguageSelection.scss';

interface LaguageSelectProps {
    language: string,
    setLanguage: (lang: string) => void
}

export default function LanguageSelection({ setLanguage, language }: LaguageSelectProps) {
    const handleChange = (event: { target: { value: string } }) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value)
    };

    const options = [
        { value: 'de', label: Languages.german },
        { value: 'en', label: Languages.english },
        { value: 'es', label: Languages.spanish },
        { value: 'fr', label: Languages.france },
        { value: 'pt', label: Languages.portuguese },
    ];

    return (
        <div className='language_wrapper' data-testid="language_selection" >
            <FormControl variant="standard">
                <Select
                    IconComponent={HiOutlineChevronDown}
                    value={language}
                    onChange={handleChange}
                    input={<BootstrapInputStyle1 />}
                >
                    {options.map((item, index) => {
                        return (
                            <MenuItem
                                className='language_menu'
                                key={index}
                                selected={item.value === i18n.language}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}