import moment from "moment";
import i18n from "../language/i18n";
import ISparePart from "../models/ISparePart";
import { IOutputData } from "../models/IOutputData";
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';

export class Utils {

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static sortSpareParts(spareParts: ISparePart[]) {
        const sparePartsWithPosition = spareParts.filter(it => it.position)
        let sortedSpareParts = spareParts.filter(it => !it.position)

        sparePartsWithPosition.sort((a, b) => { return a.position! - b.position! })
        sortedSpareParts.unshift(...sparePartsWithPosition)

        return sortedSpareParts
    }

    static tryParseDate(value: string) {
        moment.locale(i18n.language)
        const date = moment(value, "YYYY-MM-DD", true)
        return date.format("Do MMM YYYY")
    }

    static displayParts(component: IOutputData) {
        const isDocumentDisplay = component?.productDocuments?.length > 0
        const isSparePartDisplay = component?.spareParts?.length > 0
        const isProductAttrDisplay = component?.productAttributesList?.length > 0

        return { isDocumentDisplay, isSparePartDisplay, isProductAttrDisplay }
    }
}