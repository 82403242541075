import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const getFontSize = () => {
    if (window.innerWidth <= 576) {
        return 14
    } else if (window.innerWidth <= 768) {
        return 16
    } else if (window.innerWidth <= 992)
        return 17
    else if (window.innerWidth <= 1200)
        return 18
    else if (window.innerWidth <= 1440)
        return 19
    else {
        return 20
    }
}

export const BootstrapInputStyle1 = styled(InputBase)(({ theme }) => ({
    'label + &': {
    },
    '& .MuiInputBase-input': {

        border: '0',
        fontSize: getFontSize(),
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'NotoSans-Regular',
        '&:focus': {
            color: '#052a68',
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0',
            backgroundColor: '#fff',
        },
    },
}));

export const BootstrapInputStyle2 = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: getFontSize(),
        padding: '10px 16px 10px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'sans-serif',
        wordBreak: 'break-all',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#ced4da',
            boxShadow: '0',
            backgroundColor: '#fff',
        },
    },
}));
