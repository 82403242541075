import './Preview.scss'

interface PreviewProps {
    componentTitel: string
    icon: string
}

function Preview({ componentTitel, icon }: PreviewProps) {
    return (
        <div className="preview_wrapper" data-testid="preview">
            <div className="preview_sub_wrapper">
                <div className="preview_title">
                    <p className="preview_info">Evoguard Data Viewer</p>
                    <p className="component_title">{componentTitel}</p>
                </div>
                <div className="preview_icon">
                    <img className='icon' src={icon} alt="Product Icon" />
                </div>
            </div>

        </div>
    )
}

export default Preview
