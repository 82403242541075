import logo from '../../assets/company_icon.png'
import { Links } from '../../language/Texts'
import './Header.scss'

function Header() {
    return (
        <div className='header_wrapper' id='header_wrapper' data-testid="header">
            <a href={Links.homePage} target='_blank'>
                <img className='header_logo' src={logo} alt="Evoguard Icon" />
            </a>
        </div>
    )
}

export default Header
