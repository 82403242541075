import { AiOutlineRight } from 'react-icons/ai'
import { MdShare } from 'react-icons/md'
import { FooterTexts } from '../../language/Texts'
import { IOutputData } from '../../models/IOutputData'
import { EmailTextHelper } from '../../utils/EmailTextHelper'

interface ShareProps {
    component: IOutputData
}

function Share({ component }: ShareProps) {

    const handleClick = () => {
        const mailConfig = `mailto:?subject=${component.articleDescriptionS}&body=${EmailTextHelper.shareBody(component, window.location.toString())}`
        window.open(mailConfig)
    }

    return (
        <div className="share" onClick={() => handleClick()}>
            <div className="share_text">
                <div className="share_title">{FooterTexts.shareProduct}</div>
                <div className="share_section_icon"><AiOutlineRight /></div>
            </div>
            <div className="share_icon_wrapper">
                <MdShare className='share_icon' />
            </div>
        </div>
    )
}

export default Share
