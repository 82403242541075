import { FooterTexts, MailTexts } from '../../language/Texts'
import { AiOutlineRight } from 'react-icons/ai'
import { IOutputData } from '../../models/IOutputData'
import { EmailTextHelper } from '../../utils/EmailTextHelper'

interface SalesProps {
    component: IOutputData
}
function Sales({ component }: SalesProps) {

    const handleClick = () => {
        const mailConfig = `mailto:${MailTexts.salesMail}?subject=${component.articleDescriptionS}&body=${EmailTextHelper.contactBody(component, window.location.toString())}`
        window.open(mailConfig)
    }

    return (
        <div className="sales" onClick={() => handleClick()} >
            <div className="sales_title">{FooterTexts.contactSales}</div>
            <div className="sales_section_icon"> <AiOutlineRight /></div>
        </ div>
    )
}

export default Sales
