import { Link } from 'react-scroll'
import { GeneralTexts, TitelTexts } from '../../language/Texts'

interface NavbarHeaderProps {
    displayParts: {
        isDocumentDisplay: boolean,
        isSparePartDisplay: boolean,
        isProductAttrDisplay: boolean
    }
}
function NavbarHeader({ displayParts }: NavbarHeaderProps) {
    const { isDocumentDisplay, isSparePartDisplay, isProductAttrDisplay } = displayParts
    return (
        <header className="navbar_header" data-testid="navbar_header">
            <div className='navbar_list'>
                <Link activeClass="active" spy to="general">
                    {TitelTexts.general}
                </Link>
            </div>
            {isDocumentDisplay && <div className='navbar_list'>
                <Link activeClass="active" spy to="documents">
                    {TitelTexts.actualProductDocuments}
                </Link>
            </div>}
            {isSparePartDisplay && <div className='navbar_list'>
                <Link activeClass="active" spy to="sparePart">
                    {TitelTexts.spareParts}
                </Link>
            </div>}
            {isProductAttrDisplay && <div className='navbar_list'>
                <Link activeClass="active" spy to="productAttributes">
                    {TitelTexts.productAttributes}
                </Link>
            </div>}
            <div className='navbar_list'>
                <Link activeClass="active" spy to="contact">
                    {GeneralTexts.contact}
                </Link>
            </div>
        </header>
    )
}

export default NavbarHeader
