import { AiOutlineRight } from 'react-icons/ai'
import { FooterTexts, MailTexts } from '../../language/Texts'
import { IOutputData } from '../../models/IOutputData'
import { EmailTextHelper } from '../../utils/EmailTextHelper'

interface SupportProps {
    component: IOutputData
}

function Support({ component }: SupportProps) {

    const handleClick = () => {
        const mailConfig = `mailto:${MailTexts.sentTo}?subject=${component.articleDescriptionS}&body=${EmailTextHelper.contactBody(component, window.location.toString())}`
        window.open(mailConfig)
    }

    return (
        <div className="support" onClick={() => handleClick()}>
            <div className="support_title">{FooterTexts.contactSupport}</div>
            <div className="support_section_icon"> <AiOutlineRight /></div>
        </div>
    )
}

export default Support
