import React from 'react'
import { ICustomerStyle } from '../../models/ICustomerStyle'
import './Error.scss'
import Header from '../../components/Header/Header'


export interface ErrorProps {
    customerStyle: ICustomerStyle | null
}

export default function Error() {
    return (
        <div className="Error" data-testid="error">
            <Header />
            <div className="content">
                <h1 id="not-found-text">Ups... something went wrong :(</h1>
            </div>
        </div>
    )

}