import { GeneralTexts, MailTexts, TitelTexts } from '../language/Texts';
import { IOutputData } from '../models/IOutputData';
import { Utils } from './Utils';
import 'moment/locale/de';
import 'moment/locale/en-gb';

export namespace EmailTextHelper {

    export const shareBody = (component: IOutputData, digitalLink: string): string => {
        let text = ""
        text += `${MailTexts.greeting} \n\n`
        text += `${MailTexts.infoText} \n\n`
        text += TitelTexts.general + "\n"
        text += `${component.articleDescriptionS}\n`

        text = addGeneralPart(component, text)

        text += "\nLink\n"
        text += digitalLink + "\n\n"
        text += `${MailTexts.regard}\n`
        text += `${MailTexts.signature}\n\n`
        text += `${MailTexts.sentBy}`

        return encodeURIComponent(text)
    }

    export const contactBody = (component: IOutputData, digitalLink: string): string => {
        let text = ""
        text += `${MailTexts.greeting} \n\n`
        text += `${MailTexts.howCanWeHelp}\n \n \n`
        text += `${MailTexts.insertYourData}\n`
        text += `${MailTexts.firstnameLastname}\n`
        text += `${MailTexts.company}\n`
        text += `${MailTexts.phoneAndMail}\n`
        text += `${MailTexts.allowedToInsertSignature}\n\n`
        text += TitelTexts.general + "\n"

        text = addGeneralPart(component, text)

        text += " \nLink\n "
        text += digitalLink + " \n\n"
        text += `${MailTexts.urgentCase} \n\n`
        text += `${MailTexts.regard} \n`
        text += `${MailTexts.signature} \n\n`
        text += `${MailTexts.sentBy}`

        return encodeURIComponent(text)
    }

    const getSpareParts = (component: IOutputData) => {
        const soortedSpareParts = Utils.sortSpareParts(component.spareParts)
        let text = TitelTexts.spareParts + "\n"
        soortedSpareParts.forEach((part) => {
            if (part.position) {
                text += `${part.quantity}*${part.name}` + "   " + `${part.componentType}` + "  " + `Pos-Nr : ${part.position}\n`
            } else {
                text += `${part.quantity}*${part.name}` + "   " + `${part.componentType}\n`
            }
        })
        return text
    }

    const addGeneralPart = (component: IOutputData, text: string): string => {
        const componentType = component.generalAttributesList.componentType
        const serialNumber = component.generalAttributesList.serialNumber
        const productionDate = component.generalAttributesList.productionDate
        const productInfo = component.generalAttributesList.productInfo

        if (componentType) {
            text += `${GeneralTexts.componentType}: ${componentType}\n`
        }
        if (serialNumber) {
            text += `${GeneralTexts.serialNumber}: ${serialNumber}\n`
        }
        if (productionDate) {
            text += `${GeneralTexts.productionDate}: ${Utils.tryParseDate(productionDate)}\n`
        }
        if (productInfo) {
            text += `${GeneralTexts.productInfo}: ${productInfo}\n`
        }
        if (component.spareParts.length > 0) {
            text += "\n" + getSpareParts(component)
        }
        return text
    }
}