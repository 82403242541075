import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import LanguageSelection from '../../components/LanguageSelection/LanguageSelection'
import Navbar from '../../components/Navbar/Navbar'
import Preview from '../../components/Preview/Preview'
import SelectBar from '../../components/SelectBar/SelectBar'
import IResponse from '../../models/IResponse'
import { Utils } from '../../utils/Utils'
import { useEffect, useState } from 'react'
import './Main.scss'

interface MainProps {
    component: IResponse,
    language: string,
    setLanguage: (lang: string) => void
}

function Main({ component, language, setLanguage }: MainProps) {
    const [isMobileView, setIsMobileView] = useState<boolean>(false);
    const displayParts = Utils.displayParts(component?.component)

    useEffect(() => {
        // in order to change tab name
        document.title = component.component.articleDescriptionS
        resize()
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);
    }, []);

    const resize = () => {
        if (window.innerWidth <= 768) {
            setIsMobileView(true)
        } else {
            setIsMobileView(false)
        }
    }

    return (
        <div className='main_wrapper' data-testid="main">
            <LanguageSelection
                setLanguage={setLanguage}
                language={language}
            />
            <Header />
            <SelectBar
                isMobileView={isMobileView}
                displayParts={displayParts}
            />
            <Preview
                componentTitel={component?.component?.articleDescriptionS}
                icon={component?.component?.icon}
            />
            <Navbar
                isMobileView={isMobileView}
                component={component.component}
                displayParts={displayParts}
            />
            <Footer
                isMobileView={isMobileView}
            />

        </div>
    )
}

export default Main
