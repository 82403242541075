import axios from 'axios';
import IResponse from '../models/IResponse';
import version from '../version.json'

export class HttpRequests {

    async requestComponent(lang?: string): Promise<IResponse | null> {
        const requestURL = this.getRequestUrl()
        const body = {
            url: window.location.href,
            language: lang,
            type: "evoguard",
            additional: {
                frontendVersion: version.version,
                displayResolution: `${window.screen.width}x${window.screen.height}`
            }
        }

        return await axios.post(requestURL, body)
            .then(result => this.sendResponseWithVersion(result))
            .catch(error => {
                console.error('Error on getting component', error);
                throw error
            })

    }

    private getRequestUrl(): string {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return 'http://localhost:8080/api/getcomponent'
        } else {
            return `/api/getcomponent`
        }
    }

    private sendResponseWithVersion(result) {
        const version = result.headers.get("Webview-Backend-Version") ?? null
        const responseData = result.data
        if (version) {
            responseData.backendVersion = version
        }
        return responseData
    }
}